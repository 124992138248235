<template>
  <div>
    <bw-loader v-if="loading"/>
    <section v-if="!loading" class="d-block mt-50">
      <div class="container">
        <h3 class="text-primary mb-20 font-30">Regions and farmer groups/labels</h3>
        <h4 v-if="page_type === 'region'" class="text-primary mb-20">Region: {{
            regionAndFarmersGroupDetail.region_name
          }}</h4>
        <h4 v-else-if="page_type === 'group'" class="text-primary mb-20">Farmer Group/Label:
          {{ regionAndFarmersGroupDetail.group_name }}</h4>
        <h4 v-else class="text-primary mb-20">Farmer Subgroup: {{ regionAndFarmersGroupDetail.sub_group_name }}</h4>
        <div class="bg-white px-45 py-35 mb-20">
          <div class="row no-gutters">
            <div class="col-lg-3 col-12">
              <img alt="Farm" class="w-100" :src="regionAndFarmersGroupDetail.image_name" height="auto">
            </div>
            <div class="col-lg-4 col-12 ml-auto mt-lg-0 mt-20">
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Country</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.country_name }}</span>
              </div>
              <div v-if="page_type !== 'region'" class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Municipality</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.municipality_name }}</span>
              </div>
              <div v-if="page_type === 'group' || page_type === 'sub-group'"
                   class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Region</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.region_name }}</span>
              </div>
              <div v-if="page_type === 'sub-group'"
                   class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Farmer group/label</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.group_name }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Altitude (masl)</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.altitude }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Coffee species</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.coffee_species }}</span>
              </div>
            </div>
            <div class="col-lg-4 col-12 ml-auto">
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Coffee varieties</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.variety }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Harvest period main</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.harvest_period_main }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Harvest period fly</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.harvest_period_fly }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Harvest method</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.harvest_methods }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Processing method</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.processing_methods }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Drying method</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.drying_methods }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3 line-height-0">Qualities</h6>
                <span class="w-50 text-left">{{ regionAndFarmersGroupDetail.qualities }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div v-if="contractRegionGroup.length && geoPositions.length">
            <MapChart
              :enabled-map-legends="enabledMapLegends"
              :geo-positions="geoPositions"
              :map-center="mapCenter"
            />
          </div>
          <div v-else>No enough data found to display map</div>
        </div>
        <div class="row mt-60 tab-style-two">
          <div class="col-md-9 m-auto">
            <ul id="myTab" class="nav nav-pills justify-content-center border-bottom" role="tablist">
              <li class="nav-item" role="presentation">
                <a id="stab_1" aria-controls="tab_1" aria-selected="true" class="nav-link active" data-toggle="tab"
                   href="#tab_1"
                   role="tab">Farmers & coffee</a>
              </li>
              <li class="nav-item" role="presentation">
                <a id="stab_2" aria-controls="tab_2" aria-selected="false" class="nav-link" data-toggle="tab"
                   href="#tab_2"
                   role="tab">Coffee deliveries</a>
              </li>
              <li class="nav-item" role="presentation">
                <a id="stab_3" aria-controls="tab_3" aria-selected="false" class="nav-link" data-toggle="tab"
                   href="#tab_3"
                   role="tab">Resources</a>
              </li>
            </ul>
          </div>
        </div>
        <div id="myTabContent" class="tab-content mt-40 mb-50 text-left">
          <div id="tab_1" aria-labelledby="tab_1_tab" class="tab-pane fade active show" role="tabpanel">
            <div class="mt-30">
              <div class="row">
                <div class="col-lg-4 col-auto">
                  <!-- Icon Box Start -->
                  <div class="mb-45 text-center">
                    <img alt="Farmer" class="image-icon-xl" src="@/assets/img/icons/farmer-2.svg">
                  </div>
                  <div class="card height-220">
                    <div class="card-body px-55 py-30">
                      <div class="d-flex justify-content-between mb-30">
                        <h6 class="font-weight-bold text-f3 line-height-0">Number of farmers</h6>
                        <span class="text-f2">{{ regionAndFarmersGroupDetail.farmers_total }}</span>
                      </div>
                      <div class="d-flex justify-content-between mb-30">
                        <h6 class="font-weight-bold text-f3 line-height-0">Average age</h6>
                        <span class="text-f2">{{ regionAndFarmersGroupDetail.farmers_age_avg }}</span>
                      </div>
                      <div class="d-flex justify-content-between mb-30">
                        <h6 class="font-weight-bold text-f3 line-height-0">Youth (below 35)</h6>
                        <span v-show="regionAndFarmersGroupDetail.farmers_youth_share" class="text-f2">{{
                            regionAndFarmersGroupDetail.farmers_youth_share
                          }}%</span>
                        <span v-show="regionAndFarmersGroupDetail.farmers_youth_share === 0" class="text-f2">N/A</span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <h6 class="font-weight-bold text-f3 line-height-0">Female farmers</h6>
                        <span v-show="regionAndFarmersGroupDetail.farmers_female_share" class="text-f2">{{
                            regionAndFarmersGroupDetail.farmers_female_share
                          }}%</span>
                        <span v-show="regionAndFarmersGroupDetail.farmers_female_share === 0" class="text-f2">N/A</span>
                      </div>
                    </div>
                  </div>
                  <!-- Icon Box End -->
                </div>
                <div class="col-lg-4 col-auto">
                  <!-- Icon Box Start -->
                  <div class="mb-45 text-center">
                    <img alt="Farm" class="image-icon-xl" src="@/assets/img/icons/farm.svg">
                  </div>
                  <div class="card height-220">
                    <div class="card-body px-55 py-30">
                      <div class="d-flex justify-content-between mb-30">
                        <h6 class="font-weight-bold text-f3 line-height-0">Coffee area</h6>
                        <span class="text-f2">{{
                            regionAndFarmersGroupDetail.coffee_area_total
                          }} ha</span>
                      </div>
                      <div class="d-flex justify-content-between mb-30">
                        <h6 class="font-weight-bold text-f3 line-height-0">Avg. coffee area</h6>
                        <span class="text-f2">{{
                            regionAndFarmersGroupDetail.coffee_area_avg
                          }} ha</span>
                      </div>
                      <div class="d-flex justify-content-between mb-30">
                        <h6 class="font-weight-bold text-f3 line-height-0">Min. coffee area</h6>
                        <span class="text-f2">{{
                            regionAndFarmersGroupDetail.coffee_area_min
                          }} ha</span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <h6 class="font-weight-bold text-f3 line-height-0">Max. coffee area</h6>
                        <span class="text-f2">{{
                            regionAndFarmersGroupDetail.coffee_area_max
                          }} ha</span>
                      </div>
                    </div>
                  </div>
                  <!-- Icon Box End -->
                </div>
                <div class="col-lg-4 col-auto mt-lg-0 mt-20">
                  <!-- Icon Box Start -->
                  <div class="mb-45 text-center">
                    <img alt="nut" class="image-icon-xl" src="@/assets/img/icons/nut-2.svg">
                  </div>
                  <div class="card height-220">
                    <div class="card-body px-55 py-30">
                      <div class="d-flex justify-content-between mb-30">
                        <h6 class="font-weight-bold text-f3 line-height-0">Total production (GBE)</h6>
                        <span class="text-f2">{{ regionAndFarmersGroupDetail.gbe_total }} kgs</span>
                      </div>
                      <div class="d-flex justify-content-between mb-30">
                        <h6 class="font-weight-bold text-f3 line-height-0">Average productivity</h6>
                        <span class="text-f2">{{ regionAndFarmersGroupDetail.productivity_avg }} kgs/ha</span>
                      </div>
                    </div>
                  </div>
                  <!-- Icon Box End -->
                </div>
              </div>
            </div>
          </div>
          <div id="tab_2" aria-labelledby="tab_2_tab" class="tab-pane fade" role="tabpanel">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Contract Number</th>
                <th>Delivery Month</th>
                <th>NKG Exporter</th>
                <th>NKG Importer</th>
                <th>Net Weight (kgs)</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(rowData, _index) in coffeeContracts" :key="_index">
                <td v-for="(item, _i) in rowData" :key="_i">
                  <span v-html="item"></span>
                </td>
              </tr>
              <tr v-if="coffeeContracts.length === 0">
                <td :colspan="7">No data available</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div id="tab_3" aria-labelledby="tab_3" class="tab-pane fade" role="tabpanel">
            <div>
              <div v-if="isAdminUser">
                <div class="bg-primary-light en-dr-bg1 p-20">
                  <div class="row no-gutters p-lg-0 p-20">
                    <div class="col-lg-7 col-md-12 m-auto">
                      <div class="row no-gutters p-lg-0 p-20">
                        <div class="col-md-8 ml-auto">
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="w-50 m-0"> More than one resource can be added by clicking on this button </p>
                            <button class="btn btn-add" type="submit" @click="addMoreFurtherResource"> + Add</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-white py-40">
                  <div class="row efsc-sec-10">
                    <div class="col-lg-10 col-12 m-auto">
                      <div v-for="(_resource, _index) in furtherResources" :key="_index" class="form-group mb-55">
                        <div class="form-group mt-30">
                          <div class="form-row">
                            <div class="col-12 col-lg-4 col-sm-12">
                              <label class="col-form-label">Resource title</label>
                            </div>
                            <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                              <input v-model="_resource.title" class="admin-input form-control" placeholder="Your title"
                                     type="text"/>
                              <div
                                v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].title.required"
                                class="error">
                                Title is required.
                              </div>
                            </div>
                            <div class="col-lg-2">
                              <div class="d-inline-flex float-right">
                                <button
                                  v-if="furtherResources.length > 0"
                                  class="btn btn-add-success float-right"
                                  @click="removeFromChildForm(furtherResources,_index, 'api', resourcesContentsState)">
                                  <i aria-hidden="true" class="fa fa-times"></i> Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group mb-30">
                          <div class="form-row">
                            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                              <label class="col-form-label">File type</label>
                            </div>
                            <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                              <input v-model="_resource.subtitle" class="admin-input form-control"
                                     placeholder="File type"
                                     type="text">
                              <div
                                v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].subtitle.required"
                                class="error">
                                Type is required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group mb-30">
                          <div class="form-row">
                            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                              <label class="col-form-label">Date</label>
                            </div>
                            <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                              <input v-model="_resource.date"
                                     class="admin-input form-control"
                                     name="name" placeholder="Date"
                                     type="date">
                              <div
                                v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].date.required"
                                class="error">
                                Date is required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group mt-30">
                          <div class="form-row">
                            <div class="col-12 col-md-2 col-lg-4 col-sm-12">
                              <label class="col-form-label">Resource file</label>
                            </div>
                            <div class="col-12 col-md-10 col-lg-8 col-sm-12 content-block">
                              <div class="wrapper">
                                <div class="drop drop-inline drop-sm mr-30">
                                  <div class="cont">
                                    <img alt="" src="@/assets/img/icons/upload1.svg">
                                    <div v-if="!_resource.file.tsync_id" class="desc"> Upload File</div>
                                    <div v-if="_resource.file.tsync_id" class="desc">{{ _resource.file.name }}</div>
                                  </div>
                                  <input type="file" @change="onFileChange($event,_index)">
                                </div>
                                <div class="d-inline-flex">
                                  <i v-show="_resource.file.tsync_id" aria-hidden="true"
                                     class="fa fa-times ml-2 clear-file"
                                     @click="clearFile(furtherResources, _index)"> Clear</i>
                                </div>
                                <p class="mt-10">
                                  Or <br>
                                  <input v-model="_resource.more_link" class="mt-10 admin-input form-control"
                                         placeholder="Add URL"
                                         type="text">
                                </p>
                                <div
                                  v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].more_link.requiredUnless"
                                  class="error">
                                  Either resource file or more link is required.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="from-group mb-30">
                          <div class="form-row">
                            <div class="col-12 col-md-2 col-lg-4 col-sm-12">
                              <label class="col-form-label">Resource icon</label>
                            </div>
                            <div class="col-12 col-md-10 col-lg-8 col-sm-12 content-block">
                              <div class="drop drop-inline mr-30 drop-sm">
                                <div class="cont">
                                  <img
                                    v-if="_resource.icon && _resource.icon.previewUrl"
                                    :src="_resource.icon && _resource.icon.previewUrl" alt="Icon" class="icon-preview"/>
                                  <img
                                    v-else-if="_resource.icon && photoPreviewMapping[_resource.icon.tsync_id]"
                                    :src="photoPreviewMapping[_resource.icon.tsync_id]" alt="Icon"
                                    class="icon-preview"/>
                                  <img
                                    v-else-if="_resource.icon && !_resource.icon.previewUrl && !_resource.icon.relative_url"
                                    alt="" src="@/assets/img/icons/upload1.svg">
                                  <img
                                    v-else :src="_resource.icon && _resource.icon.relative_url | makeAbsUrl" alt="Icon"
                                    class="icon-preview"/>
                                  <div v-if="_resource.icon && !_resource.icon.name" class="desc"> Upload Icon</div>
                                  <div v-if="_resource.icon && _resource.icon.name" class="desc">
                                    {{ _resource.icon.name | textTruncate(15) }}
                                  </div>
                                </div>
                                <input
                                  accept="image/*"
                                  type="file"
                                  @change="onIconChange($event,_index)"
                                >
                                <div
                                  v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].icon.tsync_id.required"
                                  class="error">
                                  Icon is required.
                                </div>
                              </div>
                              <div v-if="_resource.icon && _resource.icon.name" class="d-inline-flex">
                                <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                                   @click="clearIcon(furtherResources,_index)"> Clear</i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-30">
                        <div class="row">
                          <div class="col-12 col-md-12 col-lg-10 col-sm-12">
                            <div class="text-right mt-30">
                              <div class="d-inline-flex">
                                <button v-if="!isObserverUser" class="btn btn-submit" type="button"
                                        @click="saveFurtherResource"> Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="furtherResources.length" class="mt-30">
                <div class="row">
                  <div v-for="(resource, _rIndex) in furtherResources"
                       :key="_rIndex"
                       class="col-md-12 col-lg-7 mr-auto"
                  >
                    <Resource
                      :date="resource.date"
                      :description1="resource.subtitle"
                      :description2="resource.description"
                      :download-resource="resource"
                      :icon-path="resource.icon ? resource.icon.relative_url : ''"
                      :title="resource.title"
                    ></Resource>
                  </div>
                </div>
              </div>
              <div v-else class="mt-30">
                No resource(s) available
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BwLoader from '@/components/ui/BwLoader'
import moment from 'moment'
import { required, requiredUnless } from 'vuelidate/lib/validators'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import Resource from '@/components/common/Resource'
import MapChart from '@/components/common/MapChart'
import _ from 'lodash'

export default {
  name: 'RegionFarmerGroupsDetail',
  components: {
    BwLoader,
    MapChart,
    Resource
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  props: {},
  data () {
    return {
      page_type: 'sub-group',
      regionGroupTableHeads: [
        {
          title: 'Region',
          subtitle: ''
        },
        {
          title: 'Purchase Point',
          subtitle: ''
        },
        {
          title: 'Farmer Group/Label',
          subtitle: ''
        },
        {
          title: 'Farmer Subgroup',
          subtitle: ''
        },
        {
          title: '% in Delivery',
          subtitle: ''
        },
        {
          title: 'Altitude (masl)',
          subtitle: ''
        },
        {
          title: 'Coffee species',
          subtitle: ''
        },
        {
          title: 'Harvest period (main season)',
          subtitle: ''
        },
        {
          title: 'Harvest period (Fly season)',
          subtitle: ''
        }
      ],
      mapCenter: {},
      geoPositions: [],
      loading: false,
      contract: {},
      billOfLadingTableRows: [],
      regionAndFarmersGroupRows: [],
      contractRegion: '',
      contractGroup: '',
      contractSubGroup: '',
      regionAndFarmersGroupDetail: {},
      year: 'all',
      coffeeContracts: [],
      furtherResourceGreetings: {
        title: ''
      },
      furtherResources: [
        {
          title: '',
          subtitle: '',
          more_link: '',
          date: null,
          icon: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          file: {
            file: null,
            name: null,
            tsync_id: null
          }
        }
      ],
      photoTsyncIdMapping: {},
      photoPreviewMapping: {},
      fileTsyncIdMapping: {},
      enabledMapLegends: {
        region: {
          name: 'Region',
          icon: '006330.svg'
        },
        farmerGroup: {
          name: 'Farmer Groups/Labels',
          icon: '69AA1A.svg'
        },
        farmerSubGroup: {
          name: 'Farmer Subgroups',
          icon: 'ED1C24.svg'
        }
      }
    }
  },
  computed: {
    resourcesContentsState () {
      return this.$store.state.RegionFarmerGroup.regionFarmerGroupContentItem
    },
    contractRegionGroup () {
      return this.$store.getters.getRegionFarmerGroupLocationContents
    },
    RegionFGs () {
      return this.$store.getters.getRegionFGDetailContracts
    },
    furtherResourceContents () {
      const _year = this.$route.params.id ? this.$route.params.id : 0
      return this.$store.getters.getRegionFarmerGroupResourcesBySubGroupId('sub-group-' + _year)
    }
  },
  validations: {
    year: { required },
    furtherResources: {
      required,
      $each: {
        title: {
          required
        },
        subtitle: {
          required
        },
        date: {
          required
        },
        icon: {
          tsync_id: {
            required
          }
        },
        more_link: {
          requiredUnless: requiredUnless(function (item) {
            return item.file.tsync_id !== null
          })
        },
        file: {
          requiredUnless: requiredUnless(function (item) {
            return item.more_link !== null
          })
        }
      }
    }
  },
  created () {
    this.page_type = this.$route.params.type
    this.getTraceabilityDetailPageContent()
  },
  mounted () {

  },
  methods: {
    getSubGroupContractsInformation () {
      const _vm = this
      const tableRows = []
      let _content = this.RegionFGs
      _content = _.filter(_content, function (o) {
        if (_vm.page_type === 'region') {
          return o.region.id === _vm.regionAndFarmersGroupDetail.region
        } else if (_vm.page_type === 'group') {
          if (_.get(o, 'group')) {
            return o.group.id === _vm.regionAndFarmersGroupDetail.group
          }
        } else {
          if (_.get(o, 'sub_group')) {
            return o.sub_group.id === _vm.regionAndFarmersGroupDetail.group
          }
        }
      })
      // eliminate duplicate row from list
      try {
        _content = _.uniqBy(_content, function (e) {
          return e.contract_no
        })
      } catch (e) {
        // eslint-disable-next-line no-self-assign
        _content = _content
      }
      for (const _data of _content) {
        const _contract = _data.contract
        const tempData = []
        tempData.push(_.get(_contract, 'contract_no') || 'N/A')

        let shipmentStartDate = _.get(_contract, 'shipment_start_date') || null
        if (shipmentStartDate) {
          shipmentStartDate = moment(shipmentStartDate).format('MMM YYYY')
        } else {
          shipmentStartDate = 'N/A'
        }

        let shipmentEndDate = _.get(_contract, 'shipment_end_date') || null
        if (shipmentEndDate) {
          shipmentEndDate = moment(shipmentEndDate).format('MMM YYYY')
        } else {
          shipmentEndDate = 'N/A'
        }

        if (shipmentStartDate === 'N/A' && shipmentEndDate === 'N/A') {
          tempData.push('N/A')
        } else {
          tempData.push(shipmentStartDate + ' - ' + shipmentEndDate)
        }

        tempData.push(_.get(_contract, 'exporter.name') || 'N/A')
        tempData.push(_.get(_contract, 'importer.name') || 'N/A')
        const weight = _.get(_contract, 'weight') ?? 0
        if (typeof weight === 'number') {
          tempData.push(weight.toLocaleString())
        } else {
          tempData.push(Number(weight).toLocaleString())
        }

        // tempData.push(_.get(_data, 'delivery_share') ?? 0)
        tempData.push('<a href="/traceability/' + _contract.id + '">Detail</a>')

        tableRows.push(tempData)
      }
      this.coffeeContracts = tableRows
    },
    async getContractInformation () {
      const _content = this.$store.getters.getRegionFarmerGroupDetailContents
      this.regionAndFarmersGroupDetail.region_name = _.get(_content, 'region_name') || 'N/A'
      this.regionAndFarmersGroupDetail.group_name = _.get(_content, 'group_name') || 'N/A'
      this.regionAndFarmersGroupDetail.sub_group_name = _.get(_content, 'sub_group_name') || 'N/A'
      this.regionAndFarmersGroupDetail.country_name = _.get(_content, 'country_name') || 'N/A'
      this.regionAndFarmersGroupDetail.image_name = require('@/assets/img/farm_' + _.get(_content, 'country_name') + '.jpg')
      this.regionAndFarmersGroupDetail.municipality_name = _.get(_content, 'municipality.name') || 'N/A'
      let altitudeMax = _.get(_content, 'altitude_max') || 0
      if (typeof altitudeMax === 'number') {
        altitudeMax = altitudeMax.toLocaleString()
      } else {
        altitudeMax = Number(altitudeMax).toLocaleString()
      }

      let altitudeMin = _.get(_content, 'altitude_min') || 0
      if (typeof altitudeMin === 'number') {
        altitudeMin = altitudeMin.toLocaleString()
      } else {
        altitudeMin = Number(altitudeMin).toLocaleString()
      }

      this.regionAndFarmersGroupDetail.altitude = altitudeMin + ' - ' + altitudeMax

      let coffeeSpecies = _.get(_content, 'coffee_species') || null ? _.uniqBy(_content.coffee_species.map(value => value.name), 'name').join(', ') : ''
      coffeeSpecies = coffeeSpecies || 'N/A'
      this.regionAndFarmersGroupDetail.coffee_species = coffeeSpecies
      this.regionAndFarmersGroupDetail.variety = _.get(_content, 'variety') || 'N/A'
      const harvestMethods = _.get(_content, 'harvest_methods') || null ? _.uniqBy(_content.harvest_methods.map(value => value.name), 'name').join(', ') : ''
      this.regionAndFarmersGroupDetail.harvest_methods = harvestMethods || 'N/A'

      let flyStart = _.get(_content, 'fly_start_date') ?? 0
      if (flyStart) {
        flyStart = moment(flyStart).format('DD MMM')
      } else {
        flyStart = 'N/A'
      }
      let flyEnd = _.get(_content, 'fly_end_date') ?? 0
      if (flyEnd) {
        flyEnd = moment(flyEnd).format('DD MMM')
      } else {
        flyEnd = 'N/A'
      }
      this.regionAndFarmersGroupDetail.harvest_period_fly = flyStart + ' - ' + flyEnd

      let mainStart = _.get(_content, 'main_start_date') ?? 0
      if (mainStart) {
        mainStart = moment(mainStart).format('DD MMM')
      } else {
        mainStart = 'N/A'
      }
      let mainEnd = _.get(_content, 'main_end_date') ?? 0
      if (mainEnd) {
        mainEnd = moment(mainEnd).format('DD MMM')
      } else {
        mainEnd = 'N/A'
      }
      this.regionAndFarmersGroupDetail.harvest_period_main = mainStart + ' - ' + mainEnd

      const processingMethods = _.get(_content, 'processing_methods') || null ? _.uniqBy(_content.processing_methods.map(value => value.name), 'name').join(', ') : ''
      this.regionAndFarmersGroupDetail.processing_methods = processingMethods || 'N/A'

      const dryingMethods = _.get(_content, 'drying_methods') || null ? _.uniqBy(_content.drying_methods.map(value => value.name), 'name').join(', ') : ''
      this.regionAndFarmersGroupDetail.drying_methods = dryingMethods || 'N/A'

      const qualities = _content.qualities ? _content.qualities.map(value => value.name).join(', ') : ''
      this.regionAndFarmersGroupDetail.qualities = qualities || 'N/A'
      this.regionAndFarmersGroupDetail.farmers_total = _.get(_content, 'farmers_total') || 0
      const farmersAgeAvg = _.get(_content, 'farmers_age_avg') || 0
      this.regionAndFarmersGroupDetail.farmers_age_avg = Math.trunc(farmersAgeAvg)
      const farmersYouthShare = _.get(_content, 'farmers_youth_share') || 0
      this.regionAndFarmersGroupDetail.farmers_youth_share = farmersYouthShare.toFixed(2)
      const farmersFemaleShare = _.get(_content, 'farmers_female_share') || 0
      this.regionAndFarmersGroupDetail.farmers_female_share = farmersFemaleShare.toFixed(2)

      let coffeeAreaTotal = _.get(_content, 'coffee_area_total') || 0
      if (typeof coffeeAreaTotal === 'number') {
        coffeeAreaTotal = coffeeAreaTotal.toLocaleString()
      } else {
        coffeeAreaTotal = Number(coffeeAreaTotal).toLocaleString()
      }
      this.regionAndFarmersGroupDetail.coffee_area_total = coffeeAreaTotal

      let coffeeAreaAvg = _.get(_content, 'coffee_area_avg') || 0
      if (typeof coffeeAreaAvg === 'number') {
        coffeeAreaAvg = coffeeAreaAvg.toLocaleString()
      } else {
        coffeeAreaAvg = Number(coffeeAreaAvg).toLocaleString()
      }
      this.regionAndFarmersGroupDetail.coffee_area_avg = coffeeAreaAvg

      let coffeeAreaMin = _.get(_content, 'coffee_area_min') || 0
      if (typeof coffeeAreaMin === 'number') {
        coffeeAreaMin = coffeeAreaMin.toLocaleString()
      } else {
        coffeeAreaMin = Number(coffeeAreaMin).toLocaleString()
      }
      this.regionAndFarmersGroupDetail.coffee_area_min = coffeeAreaMin

      let coffeeAreaMax = _.get(_content, 'coffee_area_max') || 0
      if (typeof coffeeAreaMax === 'number') {
        coffeeAreaMax = coffeeAreaMax.toLocaleString()
      } else {
        coffeeAreaMax = Number(coffeeAreaMax).toLocaleString()
      }
      this.regionAndFarmersGroupDetail.coffee_area_max = coffeeAreaMax

      let gbeTotal = _.get(_content, 'gbe_total') || 0
      if (typeof gbeTotal === 'number') {
        gbeTotal = gbeTotal.toLocaleString()
      } else {
        gbeTotal = Number(gbeTotal).toLocaleString()
      }
      this.regionAndFarmersGroupDetail.gbe_total = gbeTotal

      let productivityAvg = _.get(_content, 'productivity_avg') || 0
      if (typeof productivityAvg === 'number') {
        productivityAvg = productivityAvg.toLocaleString()
      } else {
        productivityAvg = Number(productivityAvg).toLocaleString()
      }
      this.regionAndFarmersGroupDetail.productivity_avg = productivityAvg

      this.regionAndFarmersGroupDetail.group = _.get(_content, 'group') || 'N/A'
      this.regionAndFarmersGroupDetail.region = _.get(_content, 'region') || 'N/A'

      this.getRegionAndFarmersGroupMapPosition()
    },
    async getRegionAndFarmersGroupTable () {
      const subGroups = this.contract.region_groups
      for (const _data of subGroups) {
        const _tempContainer = []
        _tempContainer.push(_.get(_data, 'region') || 'N/A')
        _tempContainer.push('N/A')
        _tempContainer.push(_.get(_data, 'group') || 'N/A')
        _tempContainer.push(_.get(_data, 'sub_group') || 'N/A')
        _tempContainer.push(_.get(_data, 'delivery_share') || 'N/A')
        _tempContainer.push(_.get(_data, 'altitude') || 'N/A')
        _tempContainer.push('N/A')
        _tempContainer.push(_.get(_data, 'harvest_period_main') || 'N/A')
        _tempContainer.push(_.get(_data, 'harvest_period_fly') || 'N/A')
        this.regionAndFarmersGroupRows.push(_tempContainer)
      }
    },
    async getTraceabilityDetailPageContent () {
      this.loading = true
      await this.$store.dispatch('getRegionFarmerGroupMenuContentItemsFromServer')
      await this.$store.dispatch('getRegionFarmerGroupsContractDetailFromServer',
        {
          id: this.$route.params.id,
          iu_type: this.page_type
        })
      // this is for regions contracts
      if (this.page_type === 'region') {
        await this.$store.dispatch('getUserContractsForRegionGroupsFromServer', { region: this.$route.params.id })
        await this.$store.dispatch('getSpecificRegionFarmerGroupLocationFromServer', { region: this.$route.params.id })
      } else if (this.page_type === 'group') {
        await this.$store.dispatch('getUserContractsForRegionGroupsFromServer', { group: this.$route.params.id })
        await this.$store.dispatch('getSpecificRegionFarmerGroupLocationFromServer', { group: this.$route.params.id })
      } else if (this.page_type === 'sub-group') {
        await this.$store.dispatch('getUserContractsForRegionGroupsFromServer', { sub_group: this.$route.params.id })
        await this.$store.dispatch('getSpecificRegionFarmerGroupLocationFromServer', { sub_group: this.$route.params.id })
      }
      await this.$store.dispatch('getTraceabilityBillOfLadingFromServer')
      await this.getContractInformation()
      await this.getSubGroupContractsInformation()
      this.loading = false
    },
    getRegionAndFarmersGroupMapPosition () {
      const regionFarmerGroups = this.contractRegionGroup
      for (const _data of regionFarmerGroups) {
        let _tempContainer = {}

        const regionGps = _.get(_data, 'region.location') || null
        const groupGps = _.get(_data, 'group.location') || null
        const subGroupGps = _.get(_data, 'sub_group.location') || null

        const regionName = _.get(_data, 'region_name') || 'N/A'
        const regionPk = _.get(_data, 'id') || 0
        const groupName = _.get(_data, 'group_name') || 'N/A'
        const groupPk = _.get(_data, 'id') || 0
        const subGroupName = _.get(_data, 'sub_group_name') || 'N/A'
        const subGroupPk = _.get(_data, 'id') || 0

        let regionLink = ''
        if (regionPk) {
          regionLink = `<a href='/farmers-region-group/detail/${regionPk}/region/'>${regionName}</a>`
        }

        let groupLink = ''
        if (groupPk) {
          groupLink = `<a href='/farmers-region-group/detail/${groupPk}/group/'>${groupName}</a>`
        }

        let subGroupLink = ''
        if (subGroupPk) {
          subGroupLink = `<a href='/farmers-region-group/detail/${subGroupPk}/sub-group/'>${subGroupName}</a>`
        }

        // Set Map Center
        if (_.isEmpty(this.mapCenter)) {
          let lat = 0
          let lng = 0
          if (!_.isEmpty(regionGps) && _.isEmpty(this.mapCenter)) {
            lat = regionGps.latitude || 0
            lng = regionGps.longitude || 0
          } else if (!_.isEmpty(groupGps) && _.isEmpty(this.mapCenter)) {
            lat = groupGps.latitude || 0
            lng = groupGps.longitude || 0
          } else if (!_.isEmpty(subGroupGps) && _.isEmpty(this.mapCenter)) {
            lat = subGroupGps.latitude || 0
            lng = subGroupGps.longitude || 0
          }
          if (!_.isEmpty(this.mapCenter && (lat || lng))) {
            this.mapCenter.lat = parseFloat(lat)
            this.mapCenter.lng = parseFloat(lng)
          }
        }
        // Region GPS
        if (!_.isEmpty(regionGps)) {
          const lat = regionGps.latitude || 0
          const lng = regionGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Region',
            description: regionLink || regionName,
            type: 'region'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Group GPS
        if (!_.isEmpty(groupGps)) {
          const lat = groupGps.latitude || 0
          const lng = groupGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Group',
            description: groupLink || groupName,
            type: 'farmerGroup'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Sub Group GPS
        if (!_.isEmpty(subGroupGps)) {
          const lat = subGroupGps.latitude || 0
          const lng = subGroupGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Subgroup',
            description: subGroupLink || subGroupName,
            type: 'farmerSubGroup'
          }

          this.geoPositions.push(_tempContainer)
        }
      }
      if (_.isEmpty(this.mapCenter)) {
        this.mapCenter.lat = 14.769611
        this.mapCenter.lng = 32.508846
      }
    },
    // Further Resources
    addMoreFurtherResource () {
      this.furtherResources.push({
        title: '',
        subtitle: '',
        more_link: '',
        date: null,
        icon: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        file: {
          file: null,
          name: null,
          tsync_id: null,
          size: 0
        }
      })
    },
    onIconChange (e, index) {
      // index => key statistics array objects index
      // if (!this.furtherResources[index].icon) {
      //   this.furtherResources[index].icon = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.furtherResources[index].icon.previewUrl = URL.createObjectURL(file)
        this.furtherResources[index].icon.file = file
        this.furtherResources[index].icon.name = file.name
        this.furtherResources[index].icon.tsync_id = this.uuidV4()
      } else {
        // if icon upload widget clicked and no image selected
        this.furtherResources[index].icon.previewUrl = null
        this.furtherResources[index].icon.file = null
        this.furtherResources[index].icon.name = null
        this.furtherResources[index].icon.tsync_id = null
      }
    },
    onFileChange (e, index) {
      // if (!this.furtherResources[index].file) {
      //   this.furtherResources[index].file = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.furtherResources[index].file.file = file
        this.furtherResources[index].file.name = file.name
        this.furtherResources[index].file.tsync_id = this.uuidV4()
        this.furtherResources[index].file.size = file.size
      } else {
        this.furtherResources[index].file.file = null
        this.furtherResources[index].file.name = null
        this.furtherResources[index].file.tsync_id = null
        this.furtherResources[index].file.size = 0
      }
    },
    async saveFurtherResource () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please fix error(s) in form.',
          type: 'error',
          duration: 5000
        })
      } else {
        const vm = this
        this.photoTsyncIdMapping = {}
        this.photoPreviewMapping = {}
        this.fileTsyncIdMapping = {}

        const keyStatsPromises = []
        const promises = await vm.furtherResources.reduce(async (memo, v) => {
          await memo
          keyStatsPromises.push(await vm.submitEachFurtherResource(v))
        }, [])
        _.unset(promises, '')
        Promise.all(keyStatsPromises).then((values) => {
          const photoPromises = []
          const filePromises = []
          values.map((item) => {
            const iconId = item.data.icon ? item.data.icon.id : 0
            const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
            if (iconId === 0 || iconTsyncId === '') return
            const photoFile = vm.photoTsyncIdMapping[iconTsyncId]
            if (_.get(photoFile, 'name')) {
              const response = vm.uploadMultipartPhoto(iconId, photoFile)
              photoPromises.push(response)
            }

            // file upload
            const fileId = item.data.file ? item.data.file.id : 0
            const fileTsyncId = item.data.file ? item.data.file.tsync_id : ''
            if (fileId === 0 || fileTsyncId === '') return
            const file = this.fileTsyncIdMapping[fileTsyncId]
            if (_.get(file, 'file.name')) {
              const fileResponse = this.uploadFile(fileId, file.file)
              filePromises.push(fileResponse)
            }
          })
          Promise.all(photoPromises).then((iconResponses) => {
            this.$notify({
              title: 'Further Resources',
              text: 'Further Resource(s) updated successfully.',
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
            // this.$forceUpdate()
          })
          Promise.all(filePromises).then((iconResponses) => {
            // this.$forceUpdate()
          })
        })
      }
    },
    async submitEachFurtherResource (data) {
      const vm = this
      const formData = _.cloneDeep(data)
      const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : vm.uuidV4()
      if (_.get(data, 'icon.file')) {
        vm.photoTsyncIdMapping[iconTsyncId] = data.icon.file
      }
      if (data.icon && data.icon.previewUrl) {
        vm.photoPreviewMapping[iconTsyncId] = data.icon.previewUrl
      }
      if (!formData.icon.id && formData.icon.name) {
        formData.icon = {
          tsync_id: iconTsyncId,
          extension: formData.icon.name.split('.').pop()
        }
      } else {
        try {
          formData.icon.extension = formData.icon.name.split('.').pop()
        } catch (e) {

        }
      }

      // File handling
      let fileSize = 0
      const fileTsyncId = (formData.file && formData.file.tsync_id) ? formData.file.tsync_id : vm.uuidV4()
      if (_.get(data, 'file.file')) {
        vm.fileTsyncIdMapping[fileTsyncId] = data.file
      }
      fileSize = _.get(data, 'file.file') ? _.get(data, 'file.file.size') : 0// uploaded file size in KB
      if (!formData.file.id && formData.file.name) {
        formData.file = {
          tsync_id: fileTsyncId,
          extension: formData.file.name.split('.').pop()
        }
      }
      if (!_.get(formData, 'file.tsync_id')) {
        delete formData.file
        formData.file = null
      }

      // Get sub group country
      const _content = this.$store.getters.getRegionFarmerGroupDetailContents
      const countryName = _.get(_content, 'country_name') || null
      const nkgCountries = _.sortBy(this.$store.getters.getNkgCountry, 'name')
      let country = nkgCountries.filter(item => item.name === countryName)
      if (country) {
        country = country[0]
      } else {
        country = {}
      }

      formData.country = country ? country.id : null
      formData.date = Date.parse(formData.date)
      formData.description = fileSize // description store file size in KB
      formData.content = this.$store.getters.getContentByAppModule(Menus.regionFG.key).id
      formData.year = 'sub-group-' + this.$route.params.id // this used for identify which sub groups resource is this
      formData.content_type = ContentTypes.RESOURCE_MINI_CARD
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.RESOURCE, Menus.regionFG.key).id
      return await this.$store.dispatch('createOrUpdateRegionFarmerGroupContentItem', formData)
    },
    isAdminPath () {
      return _.includes(this.$route.path, '/admin/')
    }
  },
  watch: {
    furtherResourceContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          const data = []
          _.cloneDeep(newValue).map((item) => {
            if (!_.get(item, 'file.name')) {
              item.file = {
                file: null,
                name: null,
                tsync_id: null
              }
            }
            if (!_.get(item, 'icon.name')) {
              item.icon = {
                previewUrl: null,
                file: null,
                name: null,
                tsync_id: null
              }
            }
            const _date = item.date
            data.push({
              ...item,
              date: moment(_date).format('yyyy-MM-DD')
            })
          })
          this.furtherResources = data
        } else {
          this.furtherResources = []
        }
      }
    },
    furtherResourceContentsGreetingContent: {
      deep: true,
      handler (newValue) {
        if (!_.isEmpty(newValue)) {
          this.furtherResourceGreetings = _.cloneDeep(newValue)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clear-file {
  cursor: pointer;
}

.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}

.line-height-0 {
  line-height: unset !important;
}
</style>
